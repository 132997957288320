import { AxiosPromise } from 'axios';
import axios from '@/api/service';
import URLs from '@/api/service/urls';
import { TOLockKey, TOLockStateResponse } from '@/types/two-otters.types';

export function getLockStatus(target: TOLockKey): AxiosPromise<TOLockStateResponse> {
  return axios.get(URLs.TwoOttersLockingLock, {
    params: {
      target,
    },
  });
}

export function sendLock(target: TOLockKey): AxiosPromise<TOLockStateResponse> {
  return axios.post(URLs.TwoOttersLockingLock, {
    target,
    op: 'lock',
  });
}

export function sendUnlock(target: TOLockKey): AxiosPromise<TOLockStateResponse> {
  return axios.post(URLs.TwoOttersLockingLock, {
    target,
    op: 'unlock',
  });
}
