
import { Component, Vue } from 'vue-property-decorator';
import Grid from '@/components/grid/Grid';
import GridCell from '@/components/grid/GridCell';
import TOEnvLockStatus from '@/components/admin/TOEnvLockStatus.vue';
import { openErrorSnackbar } from '@/utils/components';
import { TOLockState, TOLockEnvironment, TOLockKey, TOLockTarget } from '@/types/two-otters.types';
import { getLockStatus, sendLock, sendUnlock } from '@/api/two-otters-locking.api';

@Component({
  components: {
    Grid,
    GridCell,
    TOEnvLockStatus,
  },
})
export default class ToLockingStatuses extends Vue {

  isStatusesRefreshing = false;
  lockingLogs: any[] = [];
  statuses: TOLockState[] = [
    {
      type: TOLockTarget.SERVICE,
      key: 'Service-DEV',
      hasOngoingRequest: true,
      isLocked: false,
      owner: null,
    },
    {
      type: TOLockTarget.SERVICE,
      key: 'Service-QA',
      hasOngoingRequest: true,
      isLocked: false,
      owner: null,
    },
    {
      type: TOLockTarget.SERVICE,
      key: 'Service-PROD',
      hasOngoingRequest: true,
      isLocked: false,
      owner: null,
    },
    {
      type: TOLockTarget.CONSUMER,
      key: 'Consumer-DEV',
      hasOngoingRequest: true,
      isLocked: false,
      owner: null,
    },
    {
      type: TOLockTarget.CONSUMER,
      key: 'Consumer-QA',
      hasOngoingRequest: true,
      isLocked: false,
      owner: null,
    },
    {
      type: TOLockTarget.CONSUMER,
      key: 'Consumer-PROD',
      hasOngoingRequest: true,
      isLocked: false,
      owner: null,
    }
  ];
  autoRefreshTimer = 0;

  get autoRefreshIntervalSeconds(): number {
    return 30;
  }

  get btnRefreshClasses(): string[] {
    if (this.isStatusesRefreshing) {
      return ['refreshing'];
    }
    return [];
  }

  get refreshDescription(): string {
    if (!this.isStatusesRefreshing) {
      return 'Refresh statuses';
    }
    return 'Refreshing...';
  }

  get envsOrdered(): TOLockEnvironment[] {
    return [TOLockEnvironment.DEV, TOLockEnvironment.QA, TOLockEnvironment.PROD];
  }

  get services(): TOLockState[] {
    return this.statuses.filter(state => state.type === 'service');
  }

  get consumers(): TOLockState[] {
    return this.statuses.filter(state => state.type === 'consumer');
  }

  get isWaitingForAnyResponse(): boolean {
    return this.isStatusesRefreshing || this.statuses.some(state => state.hasOngoingRequest);
  }

  async updateAllStatuses() {
    try {
      this.isStatusesRefreshing = true;
      this.statuses = this.statuses.map(state => {
        state.hasOngoingRequest = true;
        return state;
      });
      const calls = this.statuses.map(state => getLockStatus(state.key));
      const result = await Promise.all(calls);
      this.statuses = result.map(({ data }) => {
        let state = this.statuses.find(state => state.key === data.key);
        if (state) {
          state.hasOngoingRequest = false;
          state.isLocked = data.locked;
          if (data.locked) {
            state.owner = data.ownerId as string;
          }
        }
        return state as TOLockState;
      });
      this.lockingLogs.unshift({
        type: 'success',
        time: new Date().toLocaleTimeString(),
        text: 'Refreshed statuses.',
      });
    } catch (ex: any) {
      openErrorSnackbar.call(this, ex.response?.data?.message ?? 'Server Error: Cannot get statuses info.');
      this.statuses = this.statuses.map(state => {
        state.hasOngoingRequest = false;
        return state;
      });
      this.lockingLogs.unshift({
        type: 'error',
        time: new Date().toLocaleTimeString(),
        text: 'Failed to refresh statuses.',
      });
    } finally {
      this.isStatusesRefreshing = false;
    }
  }

  async updateLockState(key: TOLockKey) {
    const { data } = await getLockStatus(key);
    this.statuses = this.statuses.map(state => {
      if (state.key === data.key) {
        state.isLocked = data.locked;
        if (data.locked) {
          state.owner = data.ownerId as string;
        }
      }
      return state;
    });
  }

  async onClickRefreshStatuses() {
    try {
      this.isStatusesRefreshing = true;
      await this.updateAllStatuses();
    } finally {
      this.isStatusesRefreshing = false;
    }
  }

  setStateLoading(key: TOLockKey, isLoading: boolean) {
    this.statuses = this.statuses.map(state => {
      if (state.key === key) {
        state.hasOngoingRequest = isLoading;
      }
      return state;
    });
  }

  async onLock(key: TOLockKey) {
    try {
      this.setStateLoading(key, true);
      await sendLock(key);
      await new Promise((r) => setTimeout(r, 2000));
      await this.updateLockState(key);
      this.lockingLogs.unshift({
        type: 'success',
        time: new Date().toLocaleTimeString(),
        text: `Lock request sent for ${key}.`,
      });
    } catch (ex: any) {
      openErrorSnackbar.call(this, ex.response?.data?.message ?? 'Error: Lock request failed.');
      this.lockingLogs.unshift({
        type: 'error',
        time: new Date().toLocaleTimeString(),
        text: `Failed to send lock request for ${key}.`,
      });
    } finally {
      this.setStateLoading(key, false);
    }
  }

  async onUnlock(key: TOLockKey) {
    try {
      this.setStateLoading(key, true);
      await sendUnlock(key);
      await new Promise((r) => setTimeout(r, 2000));
      await this.updateLockState(key);
      this.lockingLogs.unshift({
        type: 'success',
        time: new Date().toLocaleTimeString(),
        text: `Unlock request sent for ${key}.`,
      });
    } catch (ex: any) {
      openErrorSnackbar.call(this, ex.response?.data?.message ?? 'Error: Unlock request failed.');
      this.lockingLogs.unshift({
        type: 'error',
        time: new Date().toLocaleTimeString(),
        text: `Failed to send unlock request for ${key}.`,
      });
    } finally {
      this.setStateLoading(key, false);
    }
  }

  async created() {
    this.updateAllStatuses();
    this.autoRefreshTimer = setInterval(() => {
      if (!this.isWaitingForAnyResponse) {
        this.updateAllStatuses();
      }
    }, this.autoRefreshIntervalSeconds * 1000);
  }

  destroyed() {
    clearInterval(this.autoRefreshTimer);
  }
}
