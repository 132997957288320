
import { Component, Prop, Vue } from 'vue-property-decorator';
import { TOLockState } from '@/types/two-otters.types';
import { UserModule } from '@/store/modules/user.module';

type LockActionType = 'lock' | 'unlock';

@Component({})
export default class TOEnvLockStatus extends Vue {
  @Prop({ type: Boolean, required: true }) isLocked!: TOLockState['isLocked'];
  @Prop({ type: [String] }) owner!: TOLockState['owner'];
  @Prop({ type: Boolean, required: true }) disabled!: boolean;

  get isOwner(): boolean {
    const loggedInUserMudId = UserModule.user.mudId;
    return this.owner === loggedInUserMudId;
  }

  get classes(): string[] {
    const classNames = [];
    if (this.disabled) {
      classNames.push('disabled');
    }
    return classNames;
  }

  onClick(type: LockActionType) {
    this.$emit(type);
  };
}
